
import * as Components from "./Poketo"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "poketo"
}

